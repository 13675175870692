export default [
  {
    path: ":id",
    name: "participate",
    component: () => import("../views/Register"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: ":id/:participantId",
    name: "participateEdit",
    component: () => import("../views/RegisterEdit"),
    meta: {
      isPublic: true,
    },
  },
];
