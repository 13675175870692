import Vue from 'vue';
import i18n from '@/i18n';
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  extend
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

configure({
  defaultMessage: (_, values) => i18n.t(`vee-validate.${values._rule_}`, values)
});

// loop over all rules
for (let rule in rules) {
  extend(rule, {
    ...rules[rule] // add the rule
  });
}

// extend('secret', {
//   validate: value => value === 'example',
//   message: 'This is not the magic word'
// });

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
