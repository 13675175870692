export const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    fullShort: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  no: {
    short: {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    fullShort: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric'
    }
  }
};
