export default [
  {
    path: 'profile',
    component: () => import('../views/Profile'),
    children: [
      {
        path: '/',
        name: 'profile-edit',
        component: () => import('../views/Edit')
      },
      {
        path: ':forced/',
        name: 'profile-edit-forced',
        component: () => import('../views/Edit')
      }
    ]
  }
];
