import './assets/scss/main.scss';

import Vue from 'vue';
import App from './App.vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
Vue.use(ElementUI, { locale });

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import './validation';

import { sync } from 'vuex-router-sync';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

// Application Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHT_INSTRUMENT_KEY || ''
}});

appInsights.loadAppInsights();
appInsights.trackPageView();

Vue.config.productionTip = false;

sync(store, router);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');



