export default [
  {
    path: 'companies',
    component: () => import('../views/Companies'),
    children: [
      {
        path: '/',
        name: 'companies-index',
        component: () => import('../views/Index')
      },
      {
        path: 'create',
        name: 'companies-create',
        component: () => import('../views/Create')
      },
      {
        path: ':id',
        name: 'companies-show',
        component: () => import('../views/Show')
      },
      {
        path: ':id/edit',
        name: 'companies-edit',
        component: () => import('../views/Edit'),
      },
      {
        path: ':id/edit/link',
        name: 'companies-link',
        component: () => import('../views/CompanyLinks'),
      },
      {
        path: ':id/edit/link/:reference',
        name: 'companies-link-edit',
        component: () => import('../views/CompanyLinks'),
      },
      {
        path: ':id/users',
        name: 'companies-users',
        component: () => import('../views/CompanyUsers')
      },
      {
        path: ':id/users/create',
        name: 'companies-users-create',
        component: () => import('../views/CompanyUserCreate')
      },
      {
        path: ':companyId/buildings/create',
        name: 'companies-create-building',
        component: () => import('@/app/buildings/views/Create')
      }
    ]
  }
];
