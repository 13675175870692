export default [
    {
        path: 'resources',
        component: () => import('../views/Resources'),
        children: [
            {
                path: '/',
                name: 'resources-index',
                component: () => import('../views/Index')
            },
        ]
    }
];
