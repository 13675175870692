export default [
    {
        path: 'admin',
        component: () => import('../views/Admin'),
        children: [
            {
                path: '/',
                name: 'admin-index',
                component: () => import('../views/Index')
            },
        ]
    }
];
