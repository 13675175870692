export default [
  {
    path: "courseevent",
    component: () => import("../views/CourseEvent"),
    children: [
      {
        path: "/",
        name: "courseevent-index",
        component: () => import("../views/Index"),
      },
      {
        path: ":id/edit",
        name: "courseevent-edit",
        component: () => import("../views/Edit"),
      },
      {
        path: ":id/view",
        name: "courseevent-view",
        component: () => import("../views/View"),
      },
      {
        path: "create",
        name: "courseevent-create",
        component: () => import("../views/Create"),
      },
      {
        path: ":id/session/:sessionId",
        name: "courseevent-session-view",
        component: () => import("../views/SessionView"),
      },
    ],
  },
];
