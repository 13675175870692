export default [
  {
    path: 'courses',
    component: () => import('../views/Courses'),
    children: [
      {
        path: '/',
        name: 'courses-index',
        component: () => import('../views/Index')
      },
      {
        path: 'create',
        name: 'courses-create',
        component: () => import('../views/Create')
      },
      {
        path: ':id/edit',
        name: 'courses-edit',
        component: () => import('../views/Edit')
      },
      {
        path: ':id/show',
        name: 'courses-show',
        component: () => import('../views/Show')
      }
    ]
  }
];
