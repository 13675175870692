export default [
  {
    path: "/",
    name: "login",
    // redirect: 'my-courses-index',
    component: () => import("../views/Login"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "set-password",
    name: "set-password",
    component: () => import("../views/SetPassword"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "reset-password",
    name: "reset-password",
    component: () => import("../views/ResetPassword"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "studentlogin",
    name: "studentlogin",
    component: () => import("../views/StudentLogin"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "feidelogin",
    name: "feidelogin",
    component: () => import("../views/FeideLogin"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "registration",
    name: "registration",
    component: () => import("../views/Registration"),
    children: [
      {
        path: ":guid",
        name: ":guid",
        component: () => import("../views/Registration"),
        meta: {
          isPublic: true,
        },
      },
    ],
    meta: {
      isPublic: true,
    },
  },
];
