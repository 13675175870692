export default [
  {
    path: 'buildings',
    component: () => import('../views/Buildings'),
    children: [
      {
        path: '/',
        name: 'buildings-index',
        component: () => import('../views/Index')
      },
      {
        path: 'create',
        name: 'buildings-create',
        component: () => import('../views/Create')
      },
      {
        path: ':id',
        name: 'buildings-show',
        component: () => import('../views/Show')
      },
      {
        path: ':id/edit',
        name: 'buildings-edit',
        component: () => import('../views/Edit')
      },
      {
        path: ':buildingId/departments/create',
        name: 'buildings-create-department',
        component: () => import('@/app/departments/views/Create')
      },
      {
        path: ':id/users',
        name: 'buildings-users',
        component: () => import('../views/BuildingUsers')
      },
      {
        path: ':id/users/create',
        name: 'buildings-users-create',
        component: () => import('../views/BuildingUserCreate')
      }
    ]
  }
];
