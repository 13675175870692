export default [
  {
    path: 'coursemodules',
    component: () => import('../views/CourseModules'),
    children: [
      {
        path: ':courseId/create',
        name: 'modules-create',
        component: () => import('../views/Create')
      },
      {
        path: ':courseModuleId/edit',
        name: 'modules-edit',
        component: () => import('../views/Edit')
      }
    ]
  }
];
