import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json';
import no from './lang/no.json';
import validationEn from 'vee-validate/dist/locale/en';
import validationNo from 'vee-validate/dist/locale/nb_NO';
import { dateTimeFormats } from './dateTimeFormats';

Vue.use(VueI18n);

/*function loadLocaleMessages() {
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}*/

export default new VueI18n({
  /* locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()*/
  dateTimeFormats,
  locale: 'no',
  fallbackLocale: 'no',
  messages: {
    no: {
      ...no,
      'vee-validate': validationNo.messages
    },
    en: {
      ...en,
      'vee-validate': validationEn.messages
    }
  }
});
